import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import * as Types from '../declarations'
import * as ClientAuthUtil from '../lib/client-auth'
import { Action, ActionType } from './actions'

const reducer = (
	state: Types.AppState = exampleInitialState,
	action: Action,
) => {
	switch (action.type) {
		// case HYDRATE:
		// 	// Attention! This will overwrite client state! Real apps should use proper reconciliation.
		// 	return { ...state, ...action.payload }
		case ActionType.CHANGE_UNIT:
			return { ...state, unit: action.payload }

		case ActionType.AUTH_STATUS_VERIFIED:
			return { ...state, userAuthStatusIsKnown: true }

		case ActionType.LOGIN_SUCCEEDED:
			// console.log('NOW IN REDUCER')
			const { user } = action
			ClientAuthUtil.saveToken(action.token)
			return { ...state, userAuthStatusIsKnown: true, user }
		case ActionType.LOGOUT:
			ClientAuthUtil.removeToken()
			return {
				...state,
				userAuthStatusIsKnown: true,
				user: undefined,
			}
		case ActionType.SUGGEST_EDIT_OPEN:
			return { ...state, editFeedbackSection: action.feedbackSection }

		case ActionType.SUGGEST_EDIT_CLOSE:
			return { ...state, editFeedbackSection: undefined }

		case ActionType.OPEN_SIGNUP:
			return { ...state, signUpOpen: true }

		case ActionType.OPEN_LOGIN:
			return { ...state, logInOpen: true }

		case ActionType.CLOSE_SIGNUP:
			return { ...state, signUpOpen: false, logInOpen: false }

		default:
			return state
	}
}

export const setMetric = (newMetric: string) => {
	return { type: 'CHANGE_UNIT', payload: newMetric }
}

const persistConfig = {
	key: 'primary',
	storage,
	// whitelist: ['exampleData'] // place to select which state you want to persist
}

const persistedReducer = persistReducer(persistConfig, reducer)

const exampleInitialState: Types.AppState = {
	unit: 'metric',
	userAuthStatusIsKnown: false,
	user: undefined,
	editFeedbackSection: undefined,
	signUpOpen: false,
	logInOpen: false,
}

export function initializeStore(initialState = exampleInitialState) {
	return createStore(
		persistedReducer,
		// @ts-ignore
		initialState,
		composeWithDevTools(applyMiddleware()),
	)
}
