import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { useQuery, gql } from '@apollo/client'

import * as Actions from '../redux/actions'
import * as Selectors from '../redux/selectors'
import * as ClientAuthUtil from '../lib/client-auth'
import * as Types from '../declarations'

// todo: get pseudo user (also rename serverside type to pseudo user)
const tokenCheckQuery = gql`
	query authCheck($token: String!) {
		authCheck(token: $token) {
			isValid
			user {
				id
				role
			}
		}
	}
`

const CheckToken: React.FunctionComponent = () => {
	const dispatch = ReactRedux.useDispatch()
	const token = ClientAuthUtil.getToken()

	const userAuthStatusIsKnown = ReactRedux.useSelector(
		Selectors.userAuthStatusIsKnown,
	)

	// useQuery is always called, but we tell it to skip when there is no token
	const { data } = useQuery(tokenCheckQuery, {
		skip: !token,
		variables: { token },
	})

	React.useEffect(() => {
		if (!token && !userAuthStatusIsKnown) {
			dispatch(Actions.verifiedAuthStatus(false, undefined))
		} else if (data) {
			const {
				isValid: tokenWasValid,
				user,
			}: Types.API.Response.VerifyToken = data?.authCheck
			if (tokenWasValid && !!user) {
				dispatch(Actions.verifiedAuthStatus(tokenWasValid, user))
			} else if (!tokenWasValid) {
				dispatch(Actions.verifiedAuthStatus(false, user))
			}
		} else {
			if (!token) {
				// log them out
				dispatch(Actions.logout())
			}
		}
	}, [token, userAuthStatusIsKnown, data, dispatch])

	return null
}

export default CheckToken
