import React from 'react'
import '../style.scss'
import { AppProps } from 'next/app'
import 'regenerator-runtime/runtime'
import { Store } from 'redux'
// import Script from 'next/script'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { DefaultSeo } from 'next-seo'
import withReduxStore from '../redux/with-redux-store'
// import { IsSsrMobileContext } from '../lib/useIsMobile'
import { MantineProvider } from '@mantine/core'

import { ApolloProvider } from '@apollo/client'
import * as MatomoNext from '@socialgouv/matomo-next'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { useRouter } from 'next/router'
import * as MantineNotifications from '@mantine/notifications'

import { Alatsi, Open_Sans, Merriweather } from '@next/font/google'
import NextNProgress from 'nextjs-progressbar'

import { useApollo } from '../lib/apollo-client'
import FeedbackWidget from '../components/FeedbackWidget'
import SuggestEditModal from '../components/SuggestEditModal'
import CheckToken from '../components/CheckToken'
import VersionContext from '../contexts/VersionContext'
import * as Types from '../declarations'
import { withVersion } from '../lib/withVersion'
// import { withVersion } from '../lib/withVersion'
import { Insights } from '../insights'

interface Props extends AppProps {
	// Component: FC
	// pageProps: any
	reduxStore: Store
	version: Types.VERSION
}

const alatsi = Alatsi({
	weight: ['400'],
	subsets: ['latin'],
	display: 'swap',
	variable: '--font-alatsi',
})

const openSans = Open_Sans({
	weight: ['400', '700'],
	subsets: ['latin'],
	display: 'swap',
	variable: '--font-open-sans',
})

const merriweather = Merriweather({
	weight: ['300', '400', '700'],
	subsets: ['latin'],
	display: 'swap',
	variable: '--font-merriweather',
})

const MyApp = ({ Component, version, reduxStore, pageProps }: Props) => {
	const persistor = persistStore(reduxStore)

	const client = useApollo()

	const router = useRouter()
	const { asPath } = router

	const isAdminRoute = asPath.startsWith('/admin')

	React.useEffect(() => {
		MatomoNext.init({
			url: 'https://analytics.samt.st/piwik.php',
			siteId: version === Types.VERSION.TREKKING ? '6' : '7',
			excludeUrlsPatterns: [/^\/admin/],
		})
	}, [])

	const seo = {
		siteName:
			version === Types.VERSION.TREKKING ? 'Guided Trekking' : 'Guided Peaks',
	}

	return (
		// <main className={alatsi.className}>
		<main
			className={`${alatsi.variable} ${openSans.variable} ${merriweather.variable}`}
		>
			<NextNProgress color={'#b3490a'} options={{ showSpinner: false }} />
			<Insights />
			<ApolloProvider client={client}>
				{/* 
			// @ts-ignore */}
				<Provider store={reduxStore}>
					{/* <IsSsrMobileContext.Provider value={pageProps.isSsrMobile}> */}
					<PersistGate
						loading={<Component {...pageProps} />}
						persistor={persistor}
					>
						{/* <Script
							src="https://www.googletagmanager.com/gtag/js?id=G-HZHJQYR9R7"
							strategy="afterInteractive"
						/> */}
						{/* <Script id="google-analytics" strategy="afterInteractive">
							{`
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());

							gtag('config', 'G-HZHJQYR9R7');
							`}
						</Script> */}
						<MantineProvider
							withGlobalStyles
							withNormalizeCSS
							theme={{
								/** Put your mantine theme override here */
								colorScheme: 'light',
								primaryShade: 8,
							}}
						>
							{!isAdminRoute && (
								<GoogleAnalytics
									gaMeasurementId={
										version === Types.VERSION.TREKKING
											? 'G-HZHJQYR9R7'
											: 'G-9FW99Y56EX'
									}
									trackPageViews
								/>
							)}
							{
								<VersionContext.Provider value={version}>
									<DefaultSeo
										openGraph={{
											type: 'website',
											locale: 'en_US',
											siteName: seo.siteName,
										}}
									/>
									<Component {...pageProps} />
									<FeedbackWidget />
									<CheckToken />
									<MantineNotifications.Notifications position="top-right" />
								</VersionContext.Provider>
							}
						</MantineProvider>
					</PersistGate>
					{/* </IsSsrMobileContext.Provider> */}
				</Provider>
			</ApolloProvider>
		</main>
	)
}

MyApp.getInitialProps = withVersion(async (context) => {
	const { version } = context

	return {
		version,
	}
})

export default withReduxStore(MyApp)
