import { createContext } from 'react'
import * as Types from '../declarations'

const VersionContext = createContext(
	process.env.VERSION === 'PEAKS'
		? Types.VERSION.PEAKS
		: Types.VERSION.TREKKING,
)
// const VersionContext = createContext(null)

export default VersionContext
