import * as React from 'react'
import * as MantineCore from '@mantine/core'
import * as MantineHooks from '@mantine/hooks'
import * as MantineForm from '@mantine/form'
import * as Icons from 'tabler-icons-react'
import * as ApolloClient from '@apollo/client'
import { useRouter } from 'next/router'

import * as GQLQueries from '../lib/queries'

const FeedbackWidget = () => {
	const [opened, { open, close }] = MantineHooks.useDisclosure(false)
	const [submitFeedback, { loading: isSubmitting }] = ApolloClient.useMutation(
		GQLQueries.Mutations.SubmitFeedback,
	)
	const [isSuccess, setIsSuccess] = React.useState<boolean | undefined>(
		undefined,
	)
	const router = useRouter()
	const { asPath } = router
	const isAdminRoute = asPath.startsWith('/admin')

	const form = MantineForm.useForm({
		initialValues: {
			email: '',
			message: '',
		},

		validate: {
			// email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
			message: (value) => (!!value ? null : 'Please enter a message'),
		},
	})

	const onSubmit = async (values: { email?: string; message: string }) => {
		setIsSuccess(undefined)

		try {
			const { email, message } = values
			const response = await submitFeedback({
				variables: { email, message },
			})

			const success = response?.data?.feedback ?? false
			setIsSuccess(success)
		} catch {
			setIsSuccess(false)
		}
	}

	if (isAdminRoute) {
		return <></>
	}

	const isSendable = form.isValid()

	return (
		<>
			<MantineCore.Modal
				opened={opened}
				onClose={close}
				title="Feedback 🙏"
				size="lg"
			>
				<p>
					Please provide any feedback that could help make guided trekking more
					useful to you.
				</p>

				<form onSubmit={form.onSubmit((values) => onSubmit(values))}>
					<MantineCore.Textarea
						minRows={6}
						withAsterisk
						label="Message"
						placeholder="your@email.com"
						{...form.getInputProps('message')}
						style={{ marginBottom: '10px' }}
					/>
					<MantineCore.TextInput
						label="Email"
						placeholder="your@email.com"
						description={
							'So I can tell you once the site is improved accordingly or further understand your feedback'
						}
						{...form.getInputProps('email')}
					/>

					<br />
					{isSuccess && (
						<MantineCore.Alert
							icon={<Icons.Check size="1rem" />}
							title="Feedback received"
							color="green"
						>
							<p>
								Thank you for sharing, if you think of anything else feel free
								to send it.
							</p>
							<p>
								If you have time,{' '}
								<a
									href="https://forms.gle/e6YN6ZM4LVvnZaAE9"
									target="_blank"
									rel="noreferrer"
								>
									answering this survey
								</a>{' '}
								would also be very helpful.
							</p>
						</MantineCore.Alert>
					)}
					{isSuccess === false && (
						<MantineCore.Alert
							icon={<Icons.AlertCircle size="1rem" />}
							title="🤦‍♂️ Something went wrong"
							color="red"
						>
							message didn&apos;t send 🤷‍♂️
						</MantineCore.Alert>
					)}

					<MantineCore.Group position="right" mt="md">
						<MantineCore.Button
							disabled={!isSendable || isSubmitting}
							type="submit"
						>
							{isSubmitting ? (
								<>
									Sending&nbsp;&nbsp;&nbsp;
									<MantineCore.Loader size="sm" variant="dots" color="gray" />
								</>
							) : isSendable ? (
								'Send message'
							) : (
								'Send'
							)}
						</MantineCore.Button>
					</MantineCore.Group>
				</form>
			</MantineCore.Modal>

			<div className="bottom-corner-element right-corner">
				<MantineCore.Group position="center">
					<MantineCore.Button
						leftIcon={<Icons.Mail />}
						onClick={open}
						variant="default"
						radius={0}
					>
						Feedback
					</MantineCore.Button>
				</MantineCore.Group>
			</div>
		</>
	)
}

export default FeedbackWidget
