export enum VERSION {
	TREKKING = 'TREKKING',
	PEAKS = 'PEAKS',
}

// todo: rename trek to trip
export namespace Trek {
	export type Itinerary = {
		description: string
		days?: {
			dayNum?: number
			title: string
			description?: string
			totalElevationChange?: number
			destinationElevation?: number
			totalTime?: number
			distance?: number
		}[]
		climbSections?: {
			name: string
			elevation: number
			isSummit: boolean
			isBaseCamp: boolean
			description?: string
		}[]
	}

	export type MonthlyWeatherData = {
		monthNo?: number
		temperature: number
		rainfall: number
	}

	export type Weather = {
		description: string
		monthlyWeatherData?: MonthlyWeatherData[]
	}

	export type Gear = {
		description: string
	}

	export type Accommodation = {
		description: string
		types: string[]
		pictures?: number[]
	}

	export type Permits = {
		description: string
		permits: {
			name: string
			cost: number
			costLocal?: number
			description: string
			availability?: string
		}[]
	}

	export type Access = {
		description: string
		pictures?: number[]
	}

	export type Route = {
		description?: string
		locations: {
			name: string
			longitude: number
			latitude: number
			type: App.Map.IconType | App.Map.RouteLineType
		}[]
		routeLines: {
			type: string
			linePoints: [number, number][]
		}[]
	}

	export type Variations = {
		name: string
		pictures: number[]
		description: string // html
		daysExtra: [number] | [number, number]
	}

	export type FAQ = {
		question: string
		answer: string
	}

	export type TrekRoute = {
		startPlace: string // (where walk in begins/ends)
		endPlace: string
		// access: string // bus, plane, drive etc
		// todo: make an enum?
		routeType: string // (out and back, circuit, through hike)
	}

	export type ClimbRoute = {
		startPlace: string // (base for the climb)
		endPlace: string
		howManyDaysTotal: [number, number] | [number] | [] // could be derived from walk in + walk out + climb days
		totalGainFromStart: number
		// todo: also enumable?
		approach: string[] // 'walk in' or 'drive' or 'helicopter' or 'fly'
	}

	export type WhenToGo = {
		bestMonths: number[]
		note?: string
	}

	export type HighLevelInfo = {
		trekRoute?: TrekRoute
		climbRoute?: ClimbRoute
		// distances only if TREK
		distances?: {
			total: {
				distance: [number, number] | [number] | []
				days: [number, number] | [number] | []
				gain: number
				highestPoint: number
			}
			daily: {
				time: [number, number] | []
				distance: [number, number] | []
				elevationGain: [number, number] | []
			}
		}
		// OPTIONAL, AND ONLY IF CLIMB
		walkIn?: {
			distanceOneWay: number
			totalGain: number
			numDaysIn: [number, number] | []
			numDaysOut: [number, number] | []
		}
		// ONLY IF CLIMB
		climb?: {
			maxElevation: number
			howManyDays: [number, number] | [number] | [] // how many days from/to base camp does it take
			elevationGainFromBaseCamp: number
			numberOfHighCamps: number // (eg 4 for ama dablam - but not all always used/needed)
			highCampNote?: string // eg 'but typically not used' (island peak) or 'but most use just 2-3' (ama dablam)
		}
		cost?: {
			solo: {
				range: [number, number] | []
			}
		}
		whenToGo?: WhenToGo
		guideNeeded?: {
			required: boolean
			description: string
		}
		// routeType?: string
		// accommodation?: string[]
	}

	export type GuideLink = {
		agencyName: string
		link: string
		cost: number
		costUpper?: number
		linkId?: string
	}

	export type TrekDifficulty = {
		individualScores: {
			distance: number
			elevationGain: number
			terrain: number
			altitude: number
			climaticConditions: number
			technicalSkills: number
			supportFacilities: number
		}
		total: number
		average: number
	}

	export type SimpleDifficulty = 1 | 2 | 3 | 4 | 5 | 6 | 7

	export type ClimbDifficulty = {
		simple: SimpleDifficulty[]
	}

	export type TripJSONData = {
		// required
		// name: string
		trekOrClimb: App.DataTypes.TrekOrClimb // 'TREK' | 'CLIMB'
		description: string
		maxElevation: number
		alternativeNames?: string[]

		lengthInDays: number
		countries: string[]
		continent: string

		trekDifficulty?: TrekDifficulty

		climbDifficulty?: ClimbDifficulty

		highLevelInfo?: HighLevelInfo
		itinerary?: Itinerary
		weather?: Weather
		gear?: Gear
		services?: {
			description: string

			accommodation?: string
			food?: string
			guides?: string

			porters?: string
			medical?: string
			gear?: string

			transport?: string
			communications?: string
			atms?: string

			shops?: string
		}
		accommodation?: Accommodation
		route?: Route
		permits?: Permits
		access?: Access
		videos?: string[]
		variations?: Variations[]
		similar?: string[] // array of slugs
		faqs?: FAQ[]
		groups?: string[]
		guidedLinks: GuideLink[]
	}

	export type TrekStatus = DB.Models.TripAttributes & {
		missingRequiredHoldingFields: MissingField[]
		missingRequiredFields: MissingField[]
		missingNonRequiredFields: MissingField[]
		deltas: { draft: TripJSONData; live: TripJSONData } | undefined
	}

	export type AdminTrekStatus = {
		trip: DB.AdminPageTrek
		missingRequiredHoldingFields: MissingField[]
		missingRequiredFields: MissingField[]
		missingNonRequiredFields: MissingField[]
	}

	export type AdminSimplifiedTrekStatus = {
		trip: DB.SimplifiedTripForAdminPage
		draft: {
			missingRequiredHoldingFields: MissingField[]
			missingRequiredFields: MissingField[]
			missingNonRequiredFields: MissingField[]
			isValidHoldingPage: boolean
			isValidCompletePage: boolean
			isContentDifferentToPublic: boolean
			isUploadCountDifferentToPublic: boolean
		}
		public: {
			missingRequiredHoldingFields: MissingField[]
			missingRequiredFields: MissingField[]
			missingNonRequiredFields: MissingField[]
			isValidHoldingPage: boolean
			isValidCompletePage: boolean
		}
	}

	export enum EditSection {
		INFO = 'INFO',
		GUIDES = 'GUIDES',

		ROUTE_INFO = 'ROUTE_INFO',
		MORE_INFO = 'MORE_INFO',
		WHEN_TO_GO = 'WHEN_TO_GO',
		ITINERARY = 'ITINERARY',
		WEATHER = 'WEATHER',

		GEAR = 'GEAR',
		SERVICES = 'SERVICES',
		ACCOMMODATION = 'ACCOMMODATION',
		ROUTE_MAP = 'ROUTE_MAP',
		PERMITS = 'PERMITS',

		ACCESS = 'ACCESS',
		VIDEOS = 'VIDEOS',
		GROUPS = 'GROUPS',
		VARIATIONS = 'VARIATIONS',
		SIMILAR = 'SIMILAR',

		FAQS = 'FAQS',
		PICTURES = 'PICTURES',
		DIFFICULTY = 'DIFFICULTY',
	}

	export type MissingField = {
		field: string
		editSection: EditSection
	}
}

export type TrekMissingData = {
	name: string
	missingFields: string[]
}

// export type TreksFromCSV = {
// 	valid: Trek[]
// 	invalid: TrekMissingData[]
// }

type LatLon = {
	lat: number
	lng: number
}

export type MapBounds = {
	_ne: LatLon
	_sw: LatLon
}

export type SearchFilter = {
	text: string
	timeOfYear: number[]
	countryFilters: string[]
	continentFilters: string[]
	mapBounds?: MapBounds
	duration?: string
	heightFilter?: number
	isWithoutPermit: boolean
	routeTypeFilter?: 'TREK' | 'CLIMB'
	budgetFilter?: number
	simpleDifficultyFilter?: number[]
	trekkingDifficultyFilter?: number[]
}

export type ValidTrekMissingData = {
	name: string
	missingFields: string[]
}

export type ThumbSize = {
	name: string
	width?: number
	height?: number
}

export interface AppState {
	unit: string // todo: enum, and elsewhere
	userAuthStatusIsKnown: boolean
	user?: App.PseudoUser
	editFeedbackSection?: App.DataTypes.FeedbackSection
	signUpOpen: boolean
	logInOpen: boolean
}

export namespace App {
	export namespace DataTypes {
		export enum TrekOrClimb {
			TREK = 'TREK',
			CLIMB = 'CLIMB',
		}
		export enum UserRole {
			USER = 'USER',
			GUIDE = 'GUIDE',
			ADMIN = 'ADMIN',
			SUPER_ADMIN = 'SUPER_ADMIN',
		}
		export enum TripVersion {
			DRAFT = 'DRAFT',
			LIVE = 'LIVE',
		}
		export enum ResourceType {
			UNIQUE_TRIP = 'UNIQUE_TRIP',
			ARTICLE = 'ARTICLE',
			GUIDE = 'GUIDE',
		}
		export enum FeedbackSection {
			HIGH_LEVEL_INFO = 'HIGH_LEVEL_INFO',
			GUIDES = 'GUIDES',
			ITINERARY = 'ITINERARY',
			ROUTE_MAP = 'ROUTE_MAP',
			WEATHER = 'WEATHER',
			GEAR = 'GEAR',
			SERVICES = 'SERVICES',
			VIDEOS = 'VIDEOS',
			SIMILAR = 'SIMILAR',
			VARIATIONS = 'VARIATIONS',
			ACCESS = 'ACCESS',
			ACCOMMODATION = 'ACCOMMODATION',
			GROUPS = 'LINKS',
			PERMITS = 'PERMITS',
			SUMMIT_VIEW = 'SUMMIT_VIEW',
			FAQs = 'FAQs',
			DIFFICULTY = 'DIFFICULTY',
			PICTURES = 'PICTURES',
			SUGGEST_ROUTE = 'SUGGEST_ROUTE',
		}
	}

	export type TrekUpdate = {
		description: string
		trekOrClimb: DataTypes.TrekOrClimb
		alternativeNames: string
	}

	export type WhenToGoUpdate = {
		note: string
		bestMonths: string
	}

	export type UploadUpdate = {
		source: string
		attributionText: string
		attributionLink: string
		isDisplayedInGallery: boolean
	}

	export type TripPublishedPropertiesUpdate = {
		isApproved?: boolean
		publishedFrom?: string | null
	}

	export type Trek = {
		id: number
		name: string
		slug: string
		alternativeNames: string
		trekOrClimb: DataTypes.TrekOrClimb
		description: string
		countries: string[]
		continent: string
		thumbSlugs: string[]
		// todo: type properly
		similarTreks: string[]
		whenToGo: {
			note: string
			bestMonths: string
		} | null
	}

	export type TripCardProps = {
		name: string
		trekOrClimb: DataTypes.TrekOrClimb
		countries: string[]
		continent: string
		slug: string
		lowestCost?: number
		totalDistanceLength?: [number, number] | [number] | []
		totalDaysLength?: [number, number] | [number] | []
		height?: number
		firstUploadId: number
		trekDifficulty?: number
		climbDifficulty: [number, number] | [number] | []
		isValidCompletePage: boolean
	}

	// todo: what's this? obsolete?
	export type HomepageTrek = Pick<
		Trek,
		| 'name'
		| 'slug'
		| 'trekOrClimb'
		| 'description'
		| 'thumbSlugs'
		| 'countries'
		| 'continent'
	>

	export namespace Map {
		export const PossibleRouteLineTypews = [
			`trek-route`,
			`climb-route`,
			`side-trip`,
			`walk-in`,
		]
		export type RouteLineType = typeof PossibleRouteLineTypews[number]

		export const PossibleIconTypes = [
			'stop',
			'airport',
			'turn-back',
			'high-point',
			'side-trip',
			'drive',
			'base-camp',
			'high-camp',
			'summit',
			'start-flag',
			'end-flag',
			'start-end-flag',
		]
		export type IconType = typeof PossibleIconTypes[number]
	}

	export type HomePageTrip = Omit<
		DB.HomePageTrip,
		| 'jsonDataTrekOrClimb'
		| 'jsonDataHighLevelInfo'
		| 'jsonDataCountries'
		| 'jsonDataContinent'
		| 'jsonDataGuidedLinks'
		| 'height'
		| 'jsonDataDistances'
		| 'jsonDataTrekDays'
		| 'jsonDataClimbDays'
		| 'jsonDataBestMonths'
		| 'jsonDataPermits'
		| 'jsonDataRouteLocation'
		| 'jsonTrekDifficulty'
		| 'jsonDataClimbDifficulty'
	> & {
		lowestCost: number | null
		totalDistanceLength: [number]
		totalDaysLength?: [number]
		height: number | null
		lengthInDays?: number
		countries: string[]
		continent: string
		bestMonths: string[]
		permitsNeeded: boolean
		routeLocation: { latitude: number; longitude: number }
		trekDifficulty: number | null
		climbDifficulty?: number[]
	}

	export type PseudoUser = {
		id: number
		role: DataTypes.UserRole
	}

	export type User = {
		id: number
		role: DataTypes.UserRole
		email: string
	}

	export type RevalidationResponse = {
		success: boolean
		note?: string
		error?: string
	}

	export type RevalidateOnPeaksResponse = {
		success: boolean
		note?: string
		error?: string
	}

	export type ArticleJSONData = {
		subtitle: string
		content: string
		seoDescription: string
		coverImage: number | undefined
		tags: string[]
	}

	export type CountryJSONData = {
		blurb: string
		mainRegionsSummary: string
		highlights: {
			mostPopular: number[]
			lesserKnown: number[]
			mostDifficult: number[]
		}
		whenToClimb: string
	}

	export type GuideJSONProfile = {
		website?: string
		mainLocation?: string
		phoneNumber?: string
		email?: string
		tripAdvisorReviewURL?: string
		businessWhatsappNumber?: string
		certificationsBlurb?: string
		spokenLanguages?: string[]
		aboutInfoBlurb?: string
		youtubeVideo?: string
		youtubeChannel?: string
		logoUploadId?: number
		coverImageUploadId?: number
	}

	export type TripOffering = App.HomePageTrip & {
		offering?: API.GuideTrip
	}
}
export namespace DB {
	export namespace Models {
		export interface TripAttributes {
			id: number
			uniqueTripId: number
			version: App.DataTypes.TripVersion
			name: string
			slug: string
			jsonData: Trek.TripJSONData
			isValidHoldingPage: boolean
			isValidCompletePage: boolean
			isApproved: boolean
			publishedFrom?: string | null
			uploads?: UploadAttributes[]
			similarTrips?: TripAttributes[]
			guideTrips?: API.GuideTrip[]
		}

		export interface TrekWhenToGoAttributes {
			id: number
			trekId: number
			note: string
			bestMonths: string
		}

		export interface UploadAttributes {
			id: number
			resourceId: number | undefined
			resourceType: App.DataTypes.ResourceType
			version: App.DataTypes.TripVersion
			filename: string
			uploadedBy: number
			source: string
			attributionText: string
			attributionLink: string
			isDisplayedInGallery: boolean
			dimensionsOriginal: string
			dimensionsSmall: string
			dimensionsMedium: string
			dimensionsLarge: string
			displayOrder: number
			isApproved: boolean
			user?: {
				guideCompany?: {
					id: number
					companyName: string
				}
			} | null
		}

		export interface UserAttributes {
			id: number
			email: string
			password: string
			role: App.DataTypes.UserRole
		}

		export interface GuideCompanyAttributes {
			id: number
			userId: number
			companyName: string
			slug: string
			jsonProfile: App.GuideJSONProfile
		}

		export interface LinkClickAttributes {
			id: number
			tripId: number
			guideTripIdClicked: number
			guideTripMultiPeakIdClicked: number
			guideCompanyIdProfileClicked: number
			linkTarget: string
			ip: string
			time: string
		}

		export interface PasswordResetAttributes {
			id: number
			userId: number
			token: string
			expiry: string
		}

		export interface ArticleAttributes {
			id: number
			version: VERSION
			title: string
			slug: string
			draftJson: App.ArticleJSONData
			liveJson: App.ArticleJSONData
			isPublished: boolean
			createdAt?: string
			updatedAt?: string
		}

		export interface CountryAttributes {
			id: number
			version: VERSION
			isoCode: string
			slug: string
			json: App.CountryJSONData
			isPublished: boolean
		}

		export interface GuideTripAttributes {
			id: number
			guideCompanyId: number
			guideCompany?: GuideCompanyAttributes
			tripId: number
			price: number
			currency: string
			webLink: string
		}

		export interface GuideTripMultiPeakAttributes {
			id: number
			guideCompanyId: number
			guideCompany?: GuideCompanyAttributes
			price: number
			currency: string
			webLink: string
		}

		export interface GuideTripMultiPeakTripAttributes {
			id: number
			guideTripMultiPeakId: number
			tripId: number
		}
	}

	export type AdminPageTrek = Pick<
		Models.TripAttributes,
		| 'id'
		| 'uniqueTripId'
		| 'version'
		| 'name'
		| 'slug'
		| 'jsonData'
		| 'isValidHoldingPage'
		| 'isValidCompletePage'
		| 'isApproved'
		| 'publishedFrom'
	> & {
		similarCount: number
		uploadsCount: number
		publicGuideLinksCount: number
	}

	export type SimplifiedTripForAdminPage = {
		name: string
		slug: string
		uniqueTripId: number
		draftId: number
		publicId: number
		draftJsonData: Trek.TripJSONData
		publicJsonData: Trek.TripJSONData
		isApproved: boolean
		publishedFrom: string | null
		draftIsValidCompletePage: boolean
		publicIsValidCompletePage: boolean
		draftIsValidHoldingPage: boolean
		publicIsValidHoldingPage: boolean
		draftUploadsCount: number
		publicUploadsCount: number
		publicGuideLinksCount: number
	}

	export type HomePageTrip = Pick<
		Models.TripAttributes,
		'id' | 'name' | 'slug' | 'isValidCompletePage'
	> & {
		firstUploadId: number
		trekOrClimb: App.DataTypes.TrekOrClimb
		jsonDataHighLevelInfo: Partial<Trek.TripJSONData>
		jsonDataCountries: string
		jsonDataContinent: string
		jsonDataGuidedLinks: string
		height?: number
		jsonDataDistances: string
		jsonDataTrekDays: string
		jsonDataClimbDays: string
		jsonDataBestMonths: string
		jsonDataPermits: string
		jsonDataRouteLocation: string
		jsonDataClimbDifficulty: string
		jsonTrekDifficulty: string
		lowestCost: number
	}
}

export namespace API {
	export namespace Response {
		export type Auth = {
			token?: string
			error?: string
			user?: App.PseudoUser
		}

		export type VerifyToken = {
			isValid: boolean
			user?: App.PseudoUser
		}

		export type CreateAdminUser = {
			success: boolean
			error?: string
		}

		export type UploadUserFile = {
			success: boolean
			error?: string
			myUploads?: API.UserUpload[]
			newUploadId?: number
		}

		export type SignedURLResponse = {
			success: boolean
			signedURL: string
			key: string
		}

		export type AdminUploadsResponse = {
			items: PendingUpload[]
		}

		export type RebuildPageResponse = {
			success: boolean
			error?: string
		}

		export type UpdateTrekResponse = {
			updatedTrek?: Trek.TrekStatus
			cacheRebuild: App.RevalidationResponse
		}

		export type UpdateTripPublishedData = {
			success: boolean
			cacheRebuild: App.RevalidationResponse
		}

		export type PromoteDraftResponse = {
			success: boolean
			cacheRebuild: App.RevalidationResponse
		}

		export type UploadFileResponse = {
			operation: {
				success: boolean
				error?: string
				trek?: DB.Models.TripAttributes
			}
			cacheRebuild?: App.RevalidationResponse
		}

		export type ImportFileResponse = {
			operation: {
				success: boolean
				error?: string
				trek?: DB.Models.TripAttributes
			}
			cacheRebuild?: App.RevalidationResponse
		}

		export type DeleteFileResponse = {
			operation: {
				success: boolean
				error?: string
				// trek?: DB.Models.TripAttributes
			}
			cacheRebuild?: App.RevalidationResponse
		}

		export type UpdateUploadResponse = {
			operation: boolean
			cacheRebuild?: App.RevalidationResponse
		}

		export type UpdateUploadUIPropertiesResponse = {
			operation: boolean
			cacheRebuild?: App.RevalidationResponse
		}

		export type ApprovePendingUploadResponse = {
			operation: boolean
			cacheRebuild?: App.RevalidationResponse
		}

		export type CreateArticleResponse = {
			success: boolean
		}

		export type GetArticlesResponse = {
			success: boolean
			collection: {
				items: API.Article[]
			}
		}

		export type GetArticleResponse = {
			success: boolean
			article: API.Article | undefined
		}

		export type UpdateArticleResponse = {
			success: boolean
			updatedArticle?: API.Article
			cacheRebuild?: App.RevalidationResponse
		}

		export type CreateCountryResponse = {
			success: boolean
			error?: string
		}

		export type GetCountriesResponse = {
			success: boolean
			collection: {
				items: API.Country[]
			}
		}

		export type GetCountryResponse = {
			success: boolean
			country: API.Country | undefined
		}

		export type UpdateCountryResponse = {
			success: boolean
			updatedCountry?: API.Country
		}

		export type CreateGuideTrip = {
			success: boolean
			error?: string
			newGuideTrips: GuideTrip[]
		}

		export type RemoveGuideTrip = {
			success: boolean
			error?: string
			newGuideTrips: GuideTrip[]
		}

		export type UpsertGuideTripMultiPeak = {
			success: boolean
			error?: string
			newTrips: GuideTripMultiPeak[]
		}

		export type RemoveGuideTripMultiPeak = {
			success: boolean
			error?: string
			newTrips: GuideTripMultiPeak[]
		}

		export type GetGuideProfile = {
			record: DB.Models.GuideCompanyAttributes | undefined
		}

		export type UpdateGuideProfile = {
			success: boolean
			error?: string
		}
	}

	export namespace Input {
		export type UpdateTrek = {
			id: number
			name?: string
			slug?: string
			jsonData: Partial<Trek.TripJSONData>
			similarTripIds?: number[]
		}

		export type RebuildPageInput = {
			uniqueTripId: number
			rebuildHomepageToo?: boolean
			token: string
		}

		export type CreateGuideTripInput = {
			// guideCompanyId: number
			tripId: number
			price: number
			currency: string
			webLink: string
		}

		export type RemoveGuideTripInput = {
			tripId: number
		}

		export type UpsertGuideTripMultiPeakInput = {
			// guideCompanyId: number
			id?: number
			price: number
			currency: string
			webLink: string
			trips: number[]
		}

		export type RemoveGuideTripMultiPeakInput = {
			guideTripripMultiPeakId: number
		}

		export type UpdateGuideProfile = {
			// guideId: number
			companyName: string
			// website: string
			jsonProfile: App.GuideJSONProfile
		}
	}

	export type UserUpload = {
		id: number
		isApproved: boolean
	}

	export type Article = DB.Models.ArticleAttributes & {
		createdAt: string
		updatedAt: string
	}

	export type Country = DB.Models.CountryAttributes & {
		createdAt: string
		updatedAt: string
	}

	export type Upload = Omit<DB.Models.UploadAttributes, 'user'> & {
		user?: {
			guideCompany: {
				id: number
				companyName: string
			}
		} | null
	}

	export type PendingUpload = Pick<
		DB.Models.UploadAttributes,
		| 'id'
		| 'version'
		| 'uploadedBy'
		| 'source'
		| 'attributionText'
		| 'attributionLink'
		| 'resourceId'
		| 'resourceType'
	> & {
		tripName: string
	}

	export type GuideTrip = DB.Models.GuideTripAttributes & {
		trip?: DB.Models.TripAttributes
	}

	export type GuideTripMultiPeak = DB.Models.GuideTripMultiPeakAttributes & {
		trips?: DB.Models.TripAttributes[]
	}

	export type GuideCompany = DB.Models.GuideCompanyAttributes & {
		guideTrips: GuideTrip[]
		guideTripMultiPeaks: GuideTripMultiPeak[]
		uploads: Upload[]
	}
}
