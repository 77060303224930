'use client'

import { SpeedInsights } from '@vercel/speed-insights/react'
import { useRouter } from 'next/router'

export function Insights() {
	const router = useRouter()
	const { pathname } = router

	return <SpeedInsights route={pathname} />
}
